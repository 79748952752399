.feedbackContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 8px;
}

.iconContainer {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--primary-100);
    color: var(--primary-600);
}
