.container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: var(--spacing-lg) var(--spacing-md);
    border-bottom: 1px solid var(--gray-300);
}

.containerRight {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
}

@media (min-width: 1200px) {
    .container {
        padding: var(--spacing-lg) var(--spacing-5xl);
    }
}
