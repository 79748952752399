.root {
    display: flex;
    gap: 12px;
}

.checkboxContainer {
    display: flex;
    position: relative;
    align-content: center;
    margin-top: 4px;
    height: 16px;
}

[type='checkbox']:checked {
    background-image: none;
    background-size: 12px;
    color: transparent;
    border: 1px solid var(--primary-600);
}

[type='checkbox']:hover,
[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus {
    border: 1px solid var(--primary-600);
}

.root input:focus {
    border: 1px solid var(--primary-300);

    outline: 2px solid transparent;
    outline-offset: 2px;

    box-shadow: 0 0 0 3px var(--primary-100);
    z-index: 10;
}

