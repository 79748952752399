.ViewingDetails {
    display: flex;
    gap: 12px;
    flex-grow: 1;
    align-content: flex-start;
}

.grid {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}

.date:first-letter {
    text-transform: uppercase;
}

.additionalDetails {
    color: var(--gray-500);
    font-size: 14px;
}
