.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: none;
    border-top: 1px solid var(--gray-200);
}

.gridContainer {
    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: var(--spacing-md);
}

.sidebar {
    grid-column: span 1;
}

.mainContent {
    grid-column: span 1;
    margin-top: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
}

.formContainer {
    overflow: hidden;
    border-radius: 8px;
    background-color: white;
    padding: 20px;
    margin-bottom: var(--spacing-md);
}

.formGrid .fullWidth {
    grid-column: span 3;
}

.input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border-width: 1px;
    font-size: var(--font-size-sm);
}

.formRow {
    display: grid;
    gap: 0.5rem;
    margin-top: var(--spacing-md);
}

.formRow.oneHalf {
    grid-template-columns: 1fr 1fr;
}

.formRow.oneThirdTwoThirds {
    grid-template-columns: 1fr 2fr;
}

.formRow.twoThirdsOneThird {
    grid-template-columns: 2fr 1fr;
}

.formRow.threeQuartersOneQuarter {
    grid-template-columns: 3fr 1fr;
}

.formRow.threeColumns {
    grid-template-columns: 1fr 1fr 1fr;
}

.formRow.fourColumns {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.fullWidth {
    grid-column: span 3;
}

.formRow input:not([type="checkbox"]) {
    width: 100%;
}

.householdDefault {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
    pointer-events: none;
    transition:
        height 300ms ease-out,
        opacity 300ms ease-out,
        padding-bottom 300ms ease-out;
}

.showFormField {
    opacity: 1;
    height: 4.5rem;
    pointer-events: all;
}

.radioButton {
    height: var(--spacing-md);
    width: var(--spacing-md);
    max-height: var(--spacing-md);
    max-width: var(--spacing-md);
}

.radioButtonWrapper {
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-md);
}

.motivationTextArea {
    margin-top: var(--spacing-xs);
}

.submitButton button{
    pointer-events: none;
}

@media (max-width: 768px) {
    .gridContainer {
        grid-template-columns: 1fr;
    }

    .sidebar, .mainContent {
        grid-column: span 1;
    }
}

@media (max-width: 768px) {
    .formRow {
        grid-template-columns: 1fr;
    }
}
