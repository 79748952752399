.root {
    width: max-content;
    display: flex;
}

.tooltip {
    position: relative;
    display: flex;
    justify-content: center;
}

.tooltip .checkbox {
    display: none;
}

.tooltip.isVisible .tooltipBalloon,
.tooltip:not(.noHover):hover .tooltipBalloon {
    visibility: visible;
}

.tooltipBalloon {
    position: absolute;

    bottom: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--gray-900);
    text-wrap: wrap;
    visibility: hidden;

    padding-left: 10.5px;
    padding-right: 10.5px;
    padding-top: 7px;
    padding-bottom: 7px;
    z-index: 9999;

    border-radius: 6px;

    margin: 5px;
    white-space: normal;

    width: max-content;
}

.tooltipBalloon > * {
    text-align: left;
    width: 100%;
    z-index: 9999;
}

.tooltipTextArrow {
    position: absolute;
    bottom: -5px;
    z-index: 59;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: var(--gray-900);
}

.tooltipBalloon > * {
    text-align: left;
}

.sm {
    max-width: 168px;
}

.md {
    max-width: 232px;
}

.lg {
    max-width: 296px;
}
