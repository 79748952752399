.listingsTableContainer {
    height: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.listingsTableWrapper {
    display: flex;
    gap: var(--spacing-sm);
    justify-content: space-between;
    padding: var(--spacing-md) var(--spacing-5xl);
}

.tableContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-200);
    padding: 0 var(--spacing-5xl);
}

.headContainer {
    background: var(--gray-50);
    width: 100%;
}

.dropdown {
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
}

.candidateContainer {
    width: 100%;
    display: block;
    align-items: center;
    align-content: center;
    text-align: center;
}

.candidateSituationBox {
    width: 104px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    z-index: 2;
    position: relative;
}

.archiveContainer {
    width: 76px;
    position: relative;
}

.archiveButton {
    position: relative;
    z-index: 2;
}

.checkbox {
    padding-left: 16px;
}

.checkboxButton {
    position: relative;
    z-index: 2;
}

.link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
