.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.householdDefault {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
    pointer-events: none;
    transition:
        height 300ms ease-out,
        opacity 300ms ease-out,
        padding-bottom 300ms ease-out;
}

.showFormField {
    opacity: 1;
    height: 5.875rem;
    padding-bottom: 1.5rem;
    pointer-events: all;
}
