.checkbox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.checkbox2 {
    display: grid;
    grid-template-columns: 0 30rem;
    align-items: center;
    gap: 25px;
}