.root {
    border-color: var(--gray-300);
    border-radius: 8px;
}

.root::-webkit-calendar-picker-indicator {
    display: none;
}

.timeInput {
    width: 120px;
}
