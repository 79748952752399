.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.listItem {
    list-style: disc;
    line-height: 36px;
    margin-left: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.errorIcon {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 32px;
    background: var(--gray-100);
}

.errorIcon i {
    font-size: 32px;
}

.errorHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-md);
}

.buttonContainer {
    display: flex;
    gap: 8px;
}
