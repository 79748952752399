.root {
    background: var(--primary-25);
    border: var(--primary-300) 1px solid;
    border-radius: 8px;
    min-height: 465px;
    min-width: 312px;
    max-width: 100%;
    max-height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
}

.headerContainer {
    display: flex;
    flex-direction: row;
}

.header {
    display: grid;
    padding-bottom: 16px;
    gap: 16px;
}

.header img {
    width: 137px;
    height: 32px;
}

.poweredBy {
    margin-bottom: 6px;
}

.badgeContainer {
    margin-top: 26px;
    margin-left: 40px;
    width: 72px;
    height: 28px;
    border-radius: 16px;
    border: var(--primary-600) 1px solid;
    background-color: var(--primary-100);
    color: var(--primary-600);
    text-align: center;
}

.badge {
    margin-top: 2px;
    margin-left: 9px;
    margin-right: 9px;
}

.titles {
    padding-top: 16px;
    padding-bottom: 16px;

}

.subtitle {
    margin-top: 16px;
}

.detail {
    margin-top: 8px;
}

.conditions {
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px;
}

.conditions i {
    color: var(--success-600);
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 25px;
}

.proText {
   padding-top: 16px;
}

.proIcon {
    font-size: 20px;
    vertical-align: sub;
    margin-left: 8px;
}
