.h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    color: var(--primary-700);
    white-space: pre-wrap;
}

.h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: var(--gray-800);
    white-space: pre-wrap;
}

.st1 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: var(--gray-400);
    white-space: pre-wrap;
}

.p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-500);
    white-space: pre-wrap;
}
