.backdrop {
    z-index: 50;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
}

.container {
    z-index: 51;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 447px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 24px;
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: 12px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
