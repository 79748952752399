.Toolbar {
    display: flex;
    background: var(--gray-25, #FCFCFC);
    padding: 10px 24px;
    align-items: center;
    column-gap: 20px;
    row-gap: 10px;
    align-self: stretch;
    border: 1px solid var(--gray-300, #D2D3D8);
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-wrap: wrap;
}

.toolbarSelect {
    padding-top: 4px;
    padding-bottom: 4px;
}

.verticalDivider {
    height: 24px;
    width: 1px;
    background: var(--gray-400, #979BA8);
}

.Toolbar > div {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.formatButton {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
    outline: none;
}

.formatButton[disabled] {
    opacity: .5;
    cursor: not-allowed;
}

.formatButton.selected:not(:hover) {
    background-color: var(--gray-200);
}

.formatButton:hover {
    background-color: var(--gray-100);
}

.colorContainer {
    position: relative;
}

.colorContainer .colorBoxes {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2px;
    padding: 6px;
}

.colorContainer .colorBox {
    display: flex;
    color: white;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
}

.colorContainer .colorBox:hover {
    border-color: black;
}

.colorContainer .colorBox .colorCheckIcon {
    width: 12px;
    height: 12px;
}

