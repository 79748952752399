.root {
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    padding: 16px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contact {
    display: flex;
    gap: 4px;
}
