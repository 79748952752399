.TextAreaContainer {
    position: relative;
}

.Textarea {
    position: relative;
    background-color: var(--white);
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid var(--Gray-300, #D2D3D8);
}

:global(.ql-editor) {
    min-height: 200px;
    height: 200px;
    max-height: 400px;
}

:global(.ql-editor ol) {
    padding-left: 0;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

:global(.ql-toolbar.ql-snow) {
    display: none;
}

:global(.ql-container.ql-snow) {
    border: none;
}

:global(.ql-variables-dropdown.ql-picker) {
    display: none;
}

:global(.ql-formats .custom-dropdown) {
    position: relative;
}

:global(.ql-variables-dropdown) {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle;
}

:global(.ql-variables-dropdown-label) {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 110px;
    border: 1px solid transparent;
}

:global(.ql-variables-dropdown-label:hover) {
    color: #076AE4;
}

:global(.ql-variables-dropdown-icon) {
    position: absolute;
    margin-top: -9px;
    right: 0;
    top: 50%;
    width: 18px;
}

:global(.ql-variables-dropdown .ql-variables-dropdown-options) {
    background-color: var(--white);
    display: none;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    z-index: 2;
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
}

:global(.ql-variables-dropdown.expanded .ql-variables-dropdown-options) {
    display: block;
}

:global(.ql-variables-dropdown.expanded .ql-variables-dropdown-options .ql-variables-dropdown-option) {
    cursor: pointer;
    display: block;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 500;
}

@media only screen and (min-width: 992px) {
    :global(.ql-editor) {
        height: 200px;
        max-height: 100%;
        width: 100%;
    }
}
