.listingsBucketContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.listingDetailsPage {
    height: 100vh;
    overflow-y: auto;
}

.mainTableContainer {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.listingsBucket {
    padding-top: var(--spacing-md);
    padding-left: var(--spacing-5xl);
    padding-right: var(--spacing-5xl);
    white-space: nowrap;
}
