.CalendarWidget {
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    overflow: hidden;
    width: 48px;
}

.month,
.dayOfMonth {
    color: var(--gray-500);
    font-weight: 600;
    text-align: center;
}

.month {
    text-transform: uppercase;
    background: var(--gray-100);
    font-size: 10px;
    padding: 2px 0;
}

.dayOfMonth {
    font-size: 16px;
    padding: 4px 0;
}
