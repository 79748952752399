.variableListContainer {
    display: grid;
    gap: var(--spacing-md);
}

.variableList {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-md);
}

.variableTransList {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-500);
    margin-bottom: var(--spacing-md);
}

.variableTransList:last-child {
    margin-bottom: 0;
}

.sidepanelContentWrapper {
    display: grid;
    gap: var(--spacing-md);
}

.linkButton {
    display: inline-flex;
}
