
.closeButton {
    margin-top: var(--spacing-md);
}

.horizontalNavigationTabsWrapper {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.titleSpacing {
    margin-top: var(--spacing-sm);
}
