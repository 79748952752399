.header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer a {
    text-decoration-line: underline;
}
