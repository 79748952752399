.loadingBox {
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadingInnerBox {
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.logo {
    width: auto;
    height: 7rem;
}

.loadingTitle {
    margin-bottom: 1rem;
}

.loadingTitle:after {
    width: 1.3rem;
    content: "";
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    text-align: left;
    letter-spacing: -0.2rem;
    animation: ellipsis-dot 1.4s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes ellipsis-dot {
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
    100% {
        content: "...";
    }
}

@-webkit-keyframes ellipsis-dot {
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
    100% {
        content: "...";
    }
}
