
.inputWrapper{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.textarea {
    margin-top: 0.375rem;
    border-radius: 8px;
    width: 100%;
    height: 8rem;
    resize: none;
}

.textarea.error {
    border: 1px solid var(--error-300);
}

.sendButton {
    margin-top: var(--spacing-md);
    display: flex;
    justify-content: flex-end;
}
