.sm {
    --button-y-padding: 8px;
    --button-x-padding: 14px;
    --button-font-size: var(--font-size-sm);
    --line-height: 20px;
}

.md {
    --button-y-padding: 10px;
    --button-x-padding: 16px;
    --button-font-size: var(--font-size-sm);
    --line-height: 20px;
}

.lg {
    --button-y-padding: 10px;
    --button-x-padding: 18px;
    --button-font-size: var(--font-size-md);
    --line-height: 24px;
}

.xl {
    --button-y-padding: 12px;
    --button-x-padding: 20px;
    --button-font-size: var(--font-size-md);
    --line-height: 24px;
}

.xxl {
    --button-y-padding: 16px;
    --button-x-padding: 28px;
    --button-font-size: var(--font-size-lg);
    --line-height: 28px;
}

.button.hasIcon:not(.hasTitle).lg {
    --button-y-padding: 12px;
}

.button.hasIcon:not(.hasTitle) {
    --button-x-padding: var(--button-y-padding);
}

.button {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: var(--button-y-padding) var(--button-x-padding);
    font-size: var(--button-font-size);
    line-height: var(--line-height);

    font-weight: 500;
    white-space: nowrap;

    cursor: pointer;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 var(--gray-100);
    background: var(--white);

    --focus-box-shadow-color: var(--primary-300);
}

.button.trailing {
    flex-direction: row-reverse;
}

.button.fullWidth {
    width: 100%;
}

.button:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px var(--focus-box-shadow-color);
    z-index: 10;
}

.button.primary {
    background-color: var(--primary-color);
    color: var(--primary-button-text-color);
}

.button.primary:hover {
    background-color: var(--primary-color-hover);
}

.button.primary:disabled {
    color: var(--gray-400);
    background: var(--gray-300);
    cursor: not-allowed;
}

.button.secondary {
    border: 1px solid var(--primary-700);
    color: var(--primary-600);
}

.button.secondary:hover {
    background-color: var(--gray-50);
}

.button.secondary:disabled {
    color: var(--gray-400);
    background: var(--gray-300);
    border-color: var(--gray-400);
    cursor: not-allowed;
}

.button.secondaryGray {
    border: 1px solid var(--gray-300);
    color: var(--gray-800);

    --focus-box-shadow-color: var(--gray-100);
}

.button.secondaryGray:hover {
    background-color: var(--gray-50);
}

.button.secondaryGray:disabled {
    color: var(--gray-400);
    background: var(--gray-300);
    border-color: var(--gray-400);
    cursor: not-allowed;
}

.icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
}

.leading.hasIcon.hasTitle .icon {
    margin-right: 0.5rem;
}

.xxl.leading.hasIcon.hasTitle .icon {
    margin-right: 0.75rem;
}

.trailing.hasIcon.hasTitle .icon {
    margin-left: 0.5rem;
}

.xxl.trailing.hasIcon.hasTitle .icon {
    margin-left: 0.75rem;
}
