.addressContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 388px;
}

.addressText {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 1.5rem;
}

.iconLabelsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.notFoundText {
    font-size: 0.8rem;
    color: var(--gray-500);
}
