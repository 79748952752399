.kbd {
    padding: 3px 6px;
    border-radius: 6px;
    border: 1px solid var(--gray-300);
    background: var(--gray-100);
    font-size: .75em;
    color: var(--gray-800);
    text-align: center;
    line-height: 1.125em;
    font-weight: 500;
    vertical-align: middle;
}
