.tab {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: center;
    flex-direction: row;
    position: relative;
    margin-bottom: -1px;
    padding: 0.75rem var(--spacing-md);
    border-bottom: 0.125rem solid transparent;
    line-height: 24px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
}

.tab .icon {
    color: var(--gray-500);
}

.tab.selected .icon {
    color: var(--primary-700);
}

.tab p {
    color: var(--gray-500);
}

.tab.selected p {
    color: var(--primary-700);
}

.tab div p {
    color: var(--gray-700);
}

.tab.selected div p {
    color: var(--primary-700);
}

.tab div {
    background-color: var(--gray-100);
}

.tab.selected {
    border-bottom: 2px solid var(--primary-700);
}

.tab:hover:not(.selected) .icon {
    color: var(--gray-800);
}

.tab:hover:not(.selected) p {
    color: var(--gray-800);
}

.tab:hover:not(.selected) div p {
    color: var(--gray-700);
}

.tab:hover:not(.selected) div {
    background-color: var(--gray-100);
}

.tab:hover:not(.selected) {
    border-color: var(--gray-500);
}

.tab p {
    pointer-events: none;
}
