.LinkTooltip {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 0;
    top: 0;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--gray-100);
    background-color: var(--white);
    border-color: var(--gray-100);
    display: flex;
    gap: var(--spacing-sm);
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.10),
                0 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.LinkTooltip.isVisible {
    opacity: 1;
    pointer-events: auto;
    z-index: 50;
}

.LinkTooltip .linkContainer {
    display: flex;
    width: min-content;
    max-width: 200px;
    align-items: center;
}

.LinkTooltip .linkButton {
    width: 100%;
}

.LinkTooltip .linkValue {
    display: block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
