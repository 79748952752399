
.noteText {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.freeRemainingAmountText {
    align-items: center;
    padding-top: 1.75rem;
    padding-left: 5.5rem;
    position: absolute;
}

.labelledCheckboxContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}
