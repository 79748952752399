.IconWithText {
    display: inline-block;
}

.iconWrapper {
    display: inline-flex;
    vertical-align: middle;
    margin-right: var(--spacing-sm);
}

.IconWithText.inline .iconWrapper {
    display: inline-flex;
}

.textWrapper {
    display: inline-flex;
    vertical-align: middle;
}

.IconWithText.inline .textWrapper {
    display: inline;
}
