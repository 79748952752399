.candidateContainer {
    padding: var(--spacing-md,) var(--spacing-lg)
}

.candidateHeader {
    display: flex;
    min-height: 28px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.candidateName {
    display: flex;
    flex-direction: row;
    position: relative;

    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--primary-700);
    margin-right: var(--spacing-md);
    word-break: break-word;
    hyphens: auto;
    font-family:
            ui-sans-serif,
            system-ui,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            "Helvetica Neue",
            Arial,
            "Noto Sans",
            sans-serif,
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol",
            "Noto Color Emoji";
}

.rightTop {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-sm);
    align-self: baseline;
    padding: var(--spacing-sm);
}

.detailsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 14px;
    align-items: center;
    width: 144px;
    white-space: nowrap;
}

.profileButtonContainer {
    white-space: nowrap;
}

.candidateDataCellContainer {
    padding-left: 24px;
}

.candidateHorizonDivider {
    @media (min-width: 640px) {
    }
}

.candidateViewingSection {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.candidateViewingList{
    margin-top: var(--spacing-md);
    word-break: break-word;
    overflow-wrap: break-word;
}

.candidateViewingList > * + *{
    margin-top: var(--spacing-md);
}

.viewingItem {
    display: flex;
    justify-content: space-between;
}

.candidateViewingText{
    font-size: 16px;
    color: var(--gray-800);
}

.candidateScreeningContainerBullet {
    padding-left: var(--spacing-sm);
    padding-right: var(--spacing-sm);
    align-content: center;
    width: 1.35rem;
    color: var(--gray-400);
}

.candidateScreeningButtonOptionsContainer {
    display: flex;
    align-items: center;
}

.inlineTextLoader {
    padding-right: var(--spacing-md);
}

.candidateScreeningReportDownloadLink {
    cursor: pointer;
}

.activeCandidateScreeningDocumentsDownloadLink {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1.25rem 1fr;
    align-items: center;
    gap: var(--spacing-sm);
}

.activeCandidateScreeningDocumentsDownloadLink :nth-child(2) {
    text-decoration-line: underline ;
}

.activeCandidateScreeningDocumentsDownloadLink i {
    color: var(--primary-600);
    font-size: 20px;
}

.candidateScreeningSection {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.candidateScreeningRating {
    display: ruby;
}

.candidateScreeningRatingText {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.candidateScreeningRatingText ul {
    padding-left: var(--spacing-md);
}

.candidateTenantContainer {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.candidateTenantDetails {
    margin-bottom: var(--spacing-md);
}

.candidateScreeningRatingList {
    text-align: left;
    list-style: initial;
    margin-left: var(--spacing-sm);
}

.candidateScreeningRatingBadge {
    margin-left: var(--spacing-sm);
}

.contactInfoWrapper {
    @media (min-width: 640px) {
        display: flex;
        align-items: flex-end;
    }
}

.LegacyStatusButton {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.informationRejectionMessage {
    color: var(--gray-800);
    font-family: "Plus Jakarta Sans", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.candidateExtraInfo {
    margin: var(--spacing-md) 0;
}

.badgeText {
    white-space: nowrap;
    padding-top: var(--spacing-md);
}

.tenantScreeningCard:not(:last-child) {
    padding-bottom: var(--spacing-md);
}

.candidateMessageTitle {
    margin: var(--spacing-md) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.candidateMessageContainer {
    margin: var(--spacing-md) 0;
    width: 100%;
    height: auto;
    white-space: pre-line;
}

.candidateMessageField {
    display: flex;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-200);
    background: var(--gray-50);
    margin-bottom: var(--spacing-md);
}

.candidateMessageText {
    font-size: 1rem;
    margin-top: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
    color: var(--gray-800);
}

.candidateMessageStartDate {
    margin-top: var(--spacing-sm);
    font-size: 0.875rem;
}

.candidateNotesContainer {
    padding-bottom: var(--spacing-md);
}

@media (min-width: 640px) {

}

.candidateNotesTitle {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.candidatePlaceholderNotes {
    margin-top: var(--spacing-xs);
    font-size: 1rem;
    color: var(--gray-700);
}

.candidateNotestMessageContainer {
    display: flex;
    gap: var(--spacing-sm);
    border: 1px solid var(--gray-200);
    width: 100%;
    padding: var(--spacing-md);
    border-radius: 0.5rem;
    background-color: var(--gray-50);
    margin-bottom: var(--spacing-md);
}

.candidateNotesText {
    margin-bottom: var(--spacing-sm);
    font-weight: 400;
    color: var(--gray-800);
}

.candidateNotesStartDate {
    color: var(--gray-400);
    font-weight: 600;
    margin-top: var(--spacing-sm);
    font-size: 0.875rem;
}
