.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: var(--spacing-lg);
    padding-bottom: var(--spacing-lg);
    padding-left: var(--spacing-5xl);
    padding-right: var(--spacing-5xl);
}

.headerTitle {
    margin-top: 1rem;
    font-family: Plus Jakarta Sans, Inter;
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--gray-800);
    line-height: 2.5rem;
}
