/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridLg {
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.listingCard {
    display: none;
}

@media (min-width: 768px) {
    .cardsGrid {
        grid-template-columns: auto 1fr;
    }
    .listingCard {
        display: block;
    }
}
