.PageFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-top: 1px solid var(--gray-300);
    padding: var(--spacing-lg) var(--spacing-md);
}

@media (min-width: 1200px) {
    .PageFooter {
        padding: var(--spacing-lg) var(--spacing-5xl);
    }
}
