.button {
    position: relative;
    appearance: none;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 8px; /* TODO: should not be here when there's only an icon and no value */
    border-radius: 8px;
    outline: none;
}

.button.fullWidth {
    width: 100%;
}

.button.spaceBetween {
    justify-content: space-between;
}

.button:disabled {
    cursor: not-allowed;
}

/* Sizes */
.xs {
    font-size: 12px;
    padding: 4px 10px;
}

.sm {
    font-size: 14px;
    padding: 6px 12px;
}

.md {
    font-size: 14px;
    padding: 8px 14px;
}

.lg {
    padding: 8px 16px;
    font-size: 16px;
}

.xl {
    padding: 10px 18px;
    font-size: 16px;
}

.xxl {
    padding: 14px 26px;
    font-size: 18px;
}

.iconOnly.sm {
    padding: 8px 8px;
}

.iconOnly.md,
.iconOnly.lg {
    padding: 10px 10px;
}

.iconOnly.xl {
    padding: 12px 12px;
}

.iconOnly.xxl {
    padding: 16px 16px;
}

/* Icons */
.button .iconBefore,
.button .iconAfter {
    display: flex;
}

.button.sm .icon,
.button.md .icon,
.button.lg .icon,
.button.xl .icon {
    width: 1.5rem;
    height: 1.5rem;
}

/* Variants */
/* Primary button */
.primary {
    color: var(--white);
    background: var(--primary-700);
    border: 1px solid var(--primary-700);
}

.primary:hover {
    border-color: var(--primary-600);
    background: var(--primary-600);
}

.primary.selected,
.primary:active,
.primary:focus {
    box-shadow: 0px 0px 0px 4px var(--primary-100);
}

.primary:disabled {
    background: var(--gray-200);
    border-color: var(--gray-200);
    color: var(--gray-400);
    box-shadow: none;
}

/* Secondary button */
.secondary {
    color: var(--primary-600);
    border: 1px solid var(--primary-700);
}

.secondary:hover {
    background: var(--primary-100);
    border-color: var(--primary-500);
}

.secondary.selected,
.secondary:active,
.secondary:focus {
    border-color: var(--primary-300);
    box-shadow: 0px 0px 0px 4px var(--primary-100);
}

.secondary:disabled {
    color: var(--gray-400);
    background: var(--gray-200);
    border-color: var(--gray-400);
    box-shadow: none;
}

/* Secondary gray */
.secondary-gray {
    color: var(--primary-800);
    border: 1px solid var(--gray-300);
}

.secondary-gray:hover {
    background: var(--gray-50);
}

.secondary-gray.selected,
.secondary-gray:active,
.secondary-gray:focus {
    box-shadow: 0px 0px 0px 4px var(--gray-100);
}

.secondary-gray:disabled {
    color: var(--gray-400);
    background: var(--gray-200);
    box-shadow: none;
}

/* Tetriary button */
.tetriary {
    color: var(--primary-700);
    border: 1px solid transparent;
}

.tetriary:hover {
    background-color: var(--primary-100);
    border-color: var(--primary-100);
}

.tetriary.selected,
.tetriary:active,
.tetriary:focus {
    box-shadow: 0px 0px 0px 4px var(--primary-100);
}

.tetriary:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

/* Tetriary gray */
.tetriary-gray {
    color: var(--gray-500);
    border: 1px solid transparent;
}

.tetriary-gray:hover {
    background-color: var(--gray-50);
    border-color: var(--gray-50);
}

.tetriary-gray.selected,
.tetriary-gray:active,
.tetriary-gray:focus {
    box-shadow: 0px 0px 0px 4px var(--gray-100);
}

.tetriary-gray:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

/* Link button */
.link {
    color: var(--primary-500);
    padding: 0;
    align-self: center;
    border-radius: 4px;
}

.link:hover {
    color: var(--primary-600);
}

.link.selected,
.link:active,
.link:focus {
    box-shadow: 0px 0px 0px 4px var(--gray-100);
}

.link:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

/* Link underline */
.link-underline {
    color: var(--primary-500);
    padding: 0;
    align-self: center;
    border-radius: 4px;
    font-weight: 500;
    text-decoration: underline;
}

.link-underline:hover {
    color: var(--primary-600);
}

.link-underline.selected,
.link-underline:active,
.link-underline:focus {
    box-shadow: 0px 0px 0px 4px var(--gray-100);
}

.link-underline:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

/* Link gray */
.link-gray {
    align-self: center;
    color: var(--gray-500);
    padding: 0;
    border-radius: 4px;
}

.link-gray:hover {
    color: var(--gray-800);
}

.link-gray.selected,
.link-gray:active,
.link-gray:focus {
    box-shadow: 0px 0px 0px 4px var(--gray-300);
}

.link-gray:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

/* Link underline gray */
.link-underline-gray {
    color: var(--gray-500);
    padding: 0;
    align-self: center;
    border-radius: 4px;
    font-weight: 500;
    text-decoration: underline;
}

.link-underline-gray:hover {
    color: var(--gray-800);
}

.link-underline-gray.selected,
.link-underline-gray:active,
.link-underline-gray:focus {
    box-shadow: 0px 0px 0px 4px var(--gray-300);
}

.link-underline-gray:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

/* Primary destructive */
.primary-destructive {
    color: var(--white);
    border: 1px solid var(--error-600);
    background: var(--error-600);
}

.primary-destructive:hover {
    background: var(--error-700);
}

.primary-destructive.selected,
.primary-destructive:active,
.primary-destructive:focus {
    box-shadow: 0px 0px 0px 4px var(--error-100);
}

.primary-destructive:disabled {
    background: var(--error-300);
    border-color: var(--error-300);
    box-shadow: none;
}

/* Secondary color destructive */
.secondary-color-destructive {
    color: var(--error-600);
    border: 1px solid var(--error-50);
    background: var(--error-50);
}

.secondary-color-destructive:hover {
    background: var(--error-100);
}

.secondary-color-destructive.selected,
.secondary-color-destructive:active,
.secondary-color-destructive:focus {
    box-shadow: 0px 0px 0px 4px var(--error-100);
}

.secondary-color-destructive:disabled {
    color: var(--error-300);
    box-shadow: none;
}

/* Secondary destructive */
.secondary-destructive {
    color: var(--error-600);
    border: 1px solid var(--error-300);
}

.secondary-destructive:hover {
    color: var(--error-900);
    background: var(--error-50);
    border-color: var(--error-300);
}

.secondary-destructive.selected,
.secondary-destructive:active,
.secondary-destructive:focus {
    box-shadow: 0px 0px 0px 4px var(--error-50);
}

.secondary-destructive:disabled {
    color: var(--error-300);
    background: var(--error-50);
    border-color: var(--error-300);
    box-shadow: none;
}

/* Tetriary destructive */
.tetriary-destructive {
    color: var(--error-600);
    border: 1px solid transparent;
}

.tetriary-destructive:hover {
    background: var(--error-50);
    border-color: var(--error-50);
}

.tetriary-destructive.selected,
.tetriary-destructive:active,
.tetriary-destructive:focus {
    box-shadow: 0px 0px 0px 4px var(--error-100);
}

.tetriary-destructive:disabled {
    color: var(--error-300);
    box-shadow: none;
}

/* Link destructive */
.link-destructive {
    align-self: center;
    color: var(--error-600);
    padding: 0;
    border-radius: 4px;
}

.link-destructive:hover {
    color: var(--error-900);
}

.link-destructive.selected,
.link-destructive:active,
.link-destructive:focus {
    box-shadow: 0px 0px 0px 4px var(--error-300);
}

.link-destructive:disabled {
    color: var(--error-300);
    box-shadow: none;
}

/* Menu item */
.menu-item {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-light);
    color: var(--gray-800);
    border: 1px solid transparent;
    padding: 0;
}

.menu-item:disabled {
    color: var(--gray-400);
    box-shadow: none;
}

.button.loading {
    color: transparent
}

.button.loading .spinner {
    position: absolute;
}

.button.inline,
.button.inline.selected,
.button.inline:active,
.button.inline:focus {
    box-shadow: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
