.grid {
    display: grid;
    gap: 8px;
}

.details {
    display: flex;
    gap: var(--spacing-md);
    color: var(--gray-400);
}
