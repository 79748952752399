.root {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;

    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;

    border-radius: 15px;

    z-index: 50;
}
