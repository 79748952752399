.container,
.profileImageContainer {
    position: relative;
    width: 40px;
    height: 40px;
}

.profileButton,
.profileImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    color: #FFFFFF;
    background-color: var(--primary-700);
}

.profileButtonActive {
    outline: 4px solid var(--primary-100);
}

.menuHeaderContainer {
    padding: 12px 16px;
    border-bottom: 1px solid var(--gray-200);
}

.menuHeaderSubContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 12px;
}

.profileImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuHeaderTextContainer {
    display: flex;
    flex-direction: column;
}

.menuItemContainer {
    padding: 10px 16px;
}
