:root {
    --transition-duration: 300ms;
}

.TimeslotSelector {
    display: grid;
    width: 100%;
    grid-template-columns: 100% 0%;
    transition-duration: var(--transition-duration);
    transition-timing-function: ease-in-out;
    transition-property: grid-template-columns, gap;
    gap: 0px;
}

.TimeslotSelector.selected {
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    gap: 8px;
}

.TimeslotSelector .confirmButton {
    overflow: hidden;
    pointer-events: none;
    transition: var(--transition-duration) padding ease-in-out;
}
.TimeslotSelector:not(.selected) .confirmButton {
    padding-left: 0;
    padding-right: 0;
    border-left-width: 0px;
    border-right-width: 0px;
}

.TimeslotSelector.selected .confirmButton {
    pointer-events: auto;
}

.timeslotButton:disabled {
    text-decoration: line-through;
}
