/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
    justify-items: center;
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
}
.gridLg {
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.submitButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
