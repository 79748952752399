.xxsm {
    font-size: 18px;
    line-height: 28px;
}

.xsm {
    font-size: 24px;
    line-height: 32px;
}

.sm {
    font-size: 30px;
    line-height: 38px;
}

.md {
    font-size: 36px;
    line-height: 44px;
}

.lg {
    font-size: 48px;
    line-height: 60px;
}
