.container {
    width: 100%;
    display: grid;
    gap: 16px;
}

.inputContainer {
    display: grid;
    grid-template-columns: max-content minmax(min-content, max-content);
    align-items: flex-start;
    gap: 12px;
}

.radioButtonContainer {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioButtonInput {
    width: 20px;
    height: 20px;
    display: grid;
    justify-content: center;
    align-content: center;
    border: 1px solid var(--gray-300);
    border-radius: 50%;
    color: #fff;
    background-color: #fff;
    appearance: none;
    margin: 0;
}

.radioButtonInput:checked {
    border: 1px solid var(--primary-600);
}

.radioButtonInput::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: var(--primary-600);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}

.radioButtonInput:checked::before {
    transform: scale(1);
}

.radioButtonInput:is(:checked, :focus, :hover),
.radioButtonInput:is(:checked, :focus, :hover)::before {
    box-shadow: none;
    border: 1px solid var(--primary-600) !important;
}
