.mainContainer {
    position: relative;
    overflow: hidden
}

.backgroundImage {
    position: absolute;
    left: 0;
    bottom: -0.275rem;
    z-index: -1;
}

.contentContainer {
    display: flex;
    padding: 1rem;
    gap: 8px;
}

.iconContainer {
    width: 70px;
    pointer-events: none;
}

.icon {
    vertical-align: middle;
    line-height: 48px;

    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;

    border-radius: 50%;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem 1rem 1.5rem;
}

.closeButton {
    position: absolute;
    right: 8px;
    top: 8px;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 4px;
    margin-right: 3px;
    font-size: 1.125rem;
    color: var(--gray-900);
}
