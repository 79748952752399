.container {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-bottom: 2px solid;
    border-color: transparent;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--gray-500);
    margin-bottom: -1px;
    cursor: pointer;
    user-select: none;
}

.container:not(.active):hover {
    border-color: var(--gray-500);
    color: var(--gray-800);
}

.active {
    border-color: var(--primary-700) ;
    color: var(--primary-700);
}

.required {
    color: var(--error-600);
}
