.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.divider {
    width: 100%;
    padding-left: 140px;
    padding-right: 140px;
}
