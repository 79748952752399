.bulletPoints {
    display: grid;
    grid-template-columns: 30px 10fr;
    grid-row-gap: 10px;
}

.bulletPointIcon {
    font-size: 20px;
}

.titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.logoSeparator {
    color: var(--gray-800);
    font-size: 14px;
    font-weight: 700;
}
