.mainContainer {
    display: grid;
    grid-template-columns: 60px 1fr;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: right;

}

.iconContainer {
    width: 70px;
    pointer-events: none;
}

.icon {
    vertical-align: middle;
    line-height: 48px;

    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;

    border-radius: 50%;
    background: var(--primary-100);
}

.flowyContainer {
    background-image: url('../../assets/img/flowy-modal.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 102px 95px;
    font: var(--primary-100);
}

.contentContainer {
    display: flex;
    flex-direction: column;
}

.icon i {
    color: var(--primary-600);
    font-size: 24px;

}
