.label {
    display: block;
    width: 100%;
    font-size: var(--font-size-md);
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-800, #2C364F);
    white-space: pre-wrap;
    margin-bottom: 6px;
}

.label:last-child {
    margin-bottom: 0;
}

.required {
    color: var(--error-600, #E53E3E);
}
