.iconContainer {
    position: relative;
    width: 94px;
    height: 94px;
}

.icon {
    position: absolute;
    font-size: 54px;
    color: var(--gray-700);
    left: 25%;
    top: 10%;
}
