.container {
    width: 100%;
}

.tabsContainer {
    display: inline-flex;
    border-bottom: 1px solid var(--gray-200);
}

.fullWidth {
    width: 100%;
}
