.htmlRenderer {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.5rem;
}

.htmlRenderer p {
    margin: 0 0 1rem;
}

.htmlRenderer b,
.htmlRenderer strong {
    font-weight: bold;
}

.htmlRenderer i,
.htmlRenderer em {
    font-style: italic;
}

.htmlRenderer u {
    text-decoration: underline;
}

.htmlRenderer h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.htmlRenderer h2 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
}

.htmlRenderer h3 {
    font-size: 1.17rem;
    margin-bottom: 0.83rem;
}

.htmlRenderer h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.htmlRenderer blockquote {
    margin: 1rem 0;
    padding-left: 1rem;
    border-left: 4px solid #ddd;
    color: #666;
    font-style: italic;
}

.htmlRenderer ul {
    list-style: disc;
    margin-left: 1.5rem;
}

.htmlRenderer ol {
    list-style: decimal;
    margin-left: 1.5rem;
}

.htmlRenderer li {
    margin: 0.5rem 0;
}

.htmlRenderer a {
    color: #007bff;
    text-decoration: none;
}

.htmlRenderer a:hover {
    text-decoration: underline;
}

.htmlRenderer br {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.htmlRenderer [class^="ql-indent-1"] {
    padding-left: 1rem;
}

.htmlRenderer [class^="ql-indent-2"] {
    padding-left: 2rem;
}

.htmlRenderer [class^="ql-indent-3"] {
    padding-left: 3rem;
}

.htmlRenderer [class^="ql-indent-4"] {
    padding-left: 4rem;
}

.htmlRenderer [class^="ql-indent-5"] {
    padding-left: 5rem;
}

.htmlRenderer [class^="ql-indent-6"] {
    padding-left: 6rem;
}

.htmlRenderer [class^="ql-indent-7"] {
    padding-left: 7rem;
}

.htmlRenderer [class^="ql-indent-8"] {
    padding-left: 8rem;
}
