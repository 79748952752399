.ColorSelector {
    position: relative;
}

.ColorSelector .colorBoxesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
}

.ColorSelector .colorBoxes {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 4px;
}

.ColorSelector .colorBox {
    display: flex;
    color: white;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.ColorSelector .colorBox:hover {
    border-color: black;
}

.ColorSelector .colorBox .colorCheckIcon {
    width: 12px;
    height: 12px;
}

.resetColorButton {
    padding: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.resetColorButton:hover {
    background-color: var(--gray-100);
    border-radius: 4px;
}

.resetColorButton .resetColorIcon {
    height: 16px;
    width: 16px;
}
