.contentSection {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-4xl);
    padding-top: var(--spacing-3xl);
    padding-bottom: var(--spacing-3xl);
}

.descriptionPanel {
    min-width: 312px;
    width: 312px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: var(--spacing-sm);
}

.descriptionPanel > button:last-child {
    margin-top: var(--spacing-sm);
}

.settingsPanel {
    min-width: 0;
    flex-grow: 1;
}

.contentSectionFooter {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-md);
}
.contentSectionFooterContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
    text-align: right;
}

.formRow:not(:last-child) {
    margin-bottom: var(--spacing-md);
}

.saveButton {
    width: 100%;
    padding: var(--spacing-md) var(--spacing-5xl);
    border-top: 1px solid #e8e9ec;
    position: sticky;
    bottom: 0;
    background: #ffffff;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    gap: var(--spacing-md)
}

.fullWidth {
    width: 100%;
}

.backIcon {
    margin-right: 8px;
    color: var(--gray-500);
    font-size: 20px;
}

.required {
    color: var(--error-600);
}

.listStyle {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 20px;
}

.signatureInformation {
    align-self: flex-start;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-sm);
    width: 100%;
    margin-top: 2px;
}

.draftIconContainer {
    width: 20px;
    height: 22px;
}

