/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
}
.gridLg {
    max-width: 720px;
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.humanCompositionContainer {
    width: 100%;
    max-width: 272px;
}

.submitButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.petCompositionContainer {
    width: 100%;
    max-width: 324px;
}
