.mainContainer {
    z-index: 999;
}

.bulkActionHeader {
    padding: 16px;
}

.bulkActionContainer a {
    display: grid;
    grid-template-columns: 2rem 1fr;
    padding-left: 16px;
    padding-right: 16px;
    white-space: wrap;
}

.bulkActionContainer i {
    font-size: 16px;
    color: var(--gray-800);

}

.bulkActionContainer div {
    font-size: 16px;
    color: var(--error-600)
}

