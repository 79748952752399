
.copyLinkFieldWrapper {
    padding-top: var(--spacing-sm);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--spacing-sm);
}

.textInputWrapper {
    flex: 1;
}
