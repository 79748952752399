.notificationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: var(--spacing-sm) 0;
}

.notification {
    display: flex;
    width: 100%;
    max-width: 32rem;
    border: 1px solid;
    border-radius: var(--spacing-sm);
    background-color: var(--white);
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1);
    overflow: hidden;
    pointer-events: auto;
    animation: slideInOut 8s cubic-bezier(0.4, 0.01, 0.6, 1) forwards;
}

.notificationSuccess {
    border-color: var(--gray-200);
    background-color: var(--white);
}

.notificationFailed {
    border-color: var(--gray-200);
    background-color: var(--error-600);
}

.notificationContent {
    display: flex;
    padding: var(--spacing-md);
    align-items: flex-start;
    gap: var(--spacing-md);
    width: 100%;
}

.notification:not(:last-child) {
    margin-bottom: var(--spacing-md);
}

.iconContainer {
    flex-shrink: 0;
}

.iconSuccess {
    height: var(--spacing-lg);
    width: var(--spacing-lg);
    color: #38a169;
}

.iconFailed {
    height: var(--spacing-lg);
    width: var(--spacing-lg);
    color: var(--white);
}

.messageContainer {
    flex: 1;
    padding-top: 2px;
}

.message {
    font-size: var(--spacing-md);
    font-weight: 500;
    color: var(--gray-900);
}

.notificationFailed .message {
    color: var(--white);
}

.closeButtonContainer {
    flex-shrink: 0;
}

.closeButton {
    border-radius: 4px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--white);
}

.closeButtonSuccess:hover {
    background-color: var(--gray-200);
}

.closeButtonFailed {
    background-color: var(--error-600);
    color: var(--white);
}

.closeButtonFailed:hover {
    background-color: var(--error-600);
}

.closeIcon {
    height: 20px;
    width: 20px;
}

@keyframes slideInOut {
    0% {
        transform: translateX(-120%);
    }
    5% {
        transform: translateX(0px);
    }
    90% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-120%);
    }
}
