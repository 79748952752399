.lg {
    font-size: 18px;
}

.md {
    font-size: 16px;
}

.md a {
    color: var(--primary-500);
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
}

.sm {
    font-size: 14px;
}

.sm a {
    text-decoration-line: underline;
}

.xsm {
    color: var(--gray-500);

    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.xsm a {
    text-decoration-line: underline;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
