.ScreeningNavigation {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.loadingContainer {
    color: var(--gray-500);
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    transform: translateX(8px);
    transition:
        150ms ease-in-out opacity,
        150ms ease-in-out transform;
}

.loadingContainer.isSubmitting {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}

.loadingContainer {
    display: inline-block;
}
.loadingContainer .loadingWrapper {
    position: relative;
    display: inline-block;
}

.loadingContainer .loadingItem {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    display: flex;
    padding: 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    transition:
        150ms ease-in-out opacity,
        150ms ease-in-out transform;
}

.loadingContainer.isSuccess .loadingItem.savingText,
.loadingContainer:not(.isSuccess) .loadingItem.savingSuccessText {
    position: absolute;
    opacity: 0;
    transform: translateX(8px);
}

.loadingContainer.isSuccess .loadingItem.savingSuccessText {
    transition-delay: 100ms;
}

.loadingIcon svg {
    width: 20px;
    height: 20px;
}

@media (max-width: 520px) {
    .ScreeningNavigation {
        flex-direction: column-reverse;
    }
    .loadingContainer,
    .loadingContainer .loadingWrapper,
    .loadingContainer .loadingItem {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .loadingContainer {
        height: 0px;
        transform: translate(0, 8px);
        transition:
            150ms ease-in-out height,
            150ms ease-in-out opacity 100ms,
            150ms ease-in-out transform 100ms;
    }
    .loadingContainer.isSubmitting {
        height: 37px;
        transform: translate(0, 0);
    }
    .loadingContainer.isSuccess .loadingItem.savingText,
    .loadingContainer:not(.isSuccess) .loadingItem.savingSuccessText {
        transform: translate(0, 8px);
    }
}
