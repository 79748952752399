.container {
    display: flex;
    justify-content: center;
}

.container input {
    display: none;
}

.container .unchecked {
    display: block;
}

.container .checked {
    display: none;
}

.container input:checked + .checkmark .unchecked {
    display: none;
}

.container input:checked + .checkmark .checked {
    display: block;
}
