.modalTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;
    gap: 8px;
}

.content {
    display: grid;
    grid-template-columns: 312px auto;
    gap: 24px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.supportLink {
    color: var(--primary-600);
    text-decoration: none !important;
}
