.candidateScreeningReportDownloadLink {
    display: grid;
    grid-template-columns: 1.25rem 1fr;
    align-items: center;
    gap: 8px;
}

.candidateScreeningReportDownloadLink :nth-child(2) {
    text-decoration-line: underline;
}

.candidateScreeningReportDownloadLink i {
    color: var(--primary-600);
    font-size: 20px;
}
