.wrapper {
    height: 100vh;
    overflow-y: auto;
}

.mainTableContainer {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.headerContainer {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: var(--spacing-lg) var(--spacing-5xl) var(--spacing-lg) var(--spacing-5xl);
    border-bottom-width: 1px;
    border-color: var(--gray-200);
}

.mainTableContainer {
    padding-top: var(--spacing-md);
    padding-left: var(--spacing-5xl);
    padding-right: var(--spacing-5xl);
}

.leadTable {
    table-layout: fixed;
    width: 100%;
}

.leadTable tr,
.leadTable td,
.leadTable th {
    border: 1px solid var(--gray-200);
    vertical-align: top;
    padding: 8px;
    overflow-wrap: anywhere;
    overflow: hidden;
}
