.paginationBar {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: var(--white);
    border-top-width: 1px;
    border-color: var(--gray-200);
    padding: var(--spacing-md) var(--spacing-5xl);
    z-index: 10;
    bottom: 0;
}
