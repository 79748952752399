.container {
    display: grid;
    grid-template-columns: auto max-content;
    gap: 8px;
    align-items: center;
}

.singleColumn {
    grid-template-columns: max-content;
}

.buttonContainer {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    gap: 8px;
    align-items: center;
}

.value {
    min-width: 24px;
    text-align: center;
}
