.nameLabel {
    font-weight: 500;
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badgeContainer {
    margin-top: 8px;
    margin-bottom: 8px;
}


.screeningRatingContainer {
    display: flex;
    gap: 8px;
    align-items: center;
}
