.filterContainer {
    display: flex;
    gap: var(--spacing-sm);
    justify-content: space-between;
    padding: var(--spacing-md) var(--spacing-5xl);
}

.filterWrapper {
    display: flex;
    gap: var(--spacing-sm);
    justify-content: space-between;
}

.tableContainer {
    padding-left: var(--spacing-5xl);
    padding-right: var(--spacing-5xl);
}

thead {
    background: var(--gray-50);
    width: 100%;
}

th {
    margin-top: 5px;
    padding: var(--spacing-md);
    text-align: left;
}

td {
    padding-left: var(--spacing-md);
    padding-right: var(--spacing-md);
}

.candidateDetailsContainer {
    width: 0;
    position: relative;
}

.candidateSituationContainer{
    white-space: nowrap;
    font-size: small;
    color: var(--gray-500);
    width: 0;
    position: relative;
}

.buttonContainer {
    width: 0;
    white-space: nowrap;
    padding-right: 0;
    position: relative;
}

.extraInformationButtonContainer {
    width: 0;
    white-space: nowrap;
    padding-right: 0;
    position: relative;
}

.extraInformationButton {
    position: relative;
}

.checkboxContainer {
    padding-right: var(--spacing-xs);
    width: 0;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.checkboxButton {
    position: relative;
}

.candidateDataRowContainer {
    margin-left: 32px;
}

.date {
    white-space: nowrap;
    width: 0;
    text-align: center;
    position: relative;
}

.dateContainer {
    display: flex;
}

.dateTooltip {
    position: relative;
}

.dateIconWrapper {
    display: inline-flex;
    justify-content: center;
    margin-right: var(--spacing-sm);
    color: var(--gray-800);
}

.reactedTitle {
    width: 0;
}

.checkbox {
    margin-left: var(--spacing-md);
}

.highlight {
    background-color: var(--gray-50);
}

.viewingColumn {
    width: 0;
}

.iconTextAlignment {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
}

.icon {
    font-weight: normal;
    font-size: 1rem;
    color: var(--gray-800);
}

.link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
