.mainContainer {
    position: relative;
    display: grid;
    row-gap: 8px;
    column-gap: 16px;
    width: 100%;
    max-width: 400px;
}

.mainContainer.twoColumns {
    grid-template-columns: 1fr 1fr;
}

@media (min-width: 768px) {
    .mainContainer.threeColumns {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1073px) {
    .mainContainer {
        grid-template-columns: 1fr 1fr;
    }
}

.icon {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--gray-800);
}

.iconDisabled {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--gray-300);
}

.iconLabelContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.disabledTextContainer {
    padding: 0.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    background: rgba(255, 255, 255, .5);
}

