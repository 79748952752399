.ListingDetails {
    max-width: 400px;
}

.grid {
    display: grid;
    gap: 8px;
}

.image {
    height: 160px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.details {
    display: flex;
    gap: var(--spacing-md);
    color: var(--gray-400);
}
