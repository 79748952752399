.container {
    display: grid;
    grid-template-columns: auto max-content;
    gap: 8px;
    align-items: center;
}

.containerAlt {
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-rows: max-content max-content max-content;
    gap: 8px;
    align-items: center;
}

.selectContainer {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.labelContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.inputContainer {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}

.buttonContainer {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 8px;
    align-items: center;
}

.buttonContainerAlt {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 8px;
    align-items: center;
}

.singleColumn {
    grid-template-columns: max-content;
}
