.PageWithSidePanel {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 0px;
    transition: 300ms ease-in-out grid-template-columns;
    overflow-x: hidden;
}
.PageWithSidePanel:after {
    content: '';
    position: absolute;
    background: rgba(20, 32, 60, 0.40);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 60;
    transition: 300ms ease-in-out opacity;
    pointer-events: none;
}

.PageWithSidePanel.open {
    grid-template-columns: 2fr 520px;
}

@media (max-width: 1024px) {
    .PageWithSidePanel > div {
        min-width: 504px;
    }
    .PageWithSidePanel.open:after {
        opacity: 1;
        pointer-events: auto;
    }
}

@media (max-width: 520px) {
    .PageWithSidePanel > div {
        min-width: 100vw;
    }
}
