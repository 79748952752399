.root {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    border-radius: 9999px;

    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;

    border: 1px solid;
}

.icon {
    font-size: 16px;
    line-height: 16px;
}

.iconBackground {
    display: flex;
    border-radius: 100%;
    margin-left: -4px;
    line-height: 10.66px;
    font-size: 10.66px;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
}
