.Card {
    border-radius: 8px;
    background: var(--white);
}

.Card.hasPadding {
    padding: var(--spacing-lg);
}

.Card.elevated {
    box-shadow:
        0 4px 8px -2px rgba(16, 24, 40, 0.10),
        0 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.Card.border {
    border-width: 1px;
    border-style: solid;
}
