.LoadingSpinner {
    width: 20px;
    height: 20px;
    animation: spin 1s cubic-bezier(.17,.67,.83,.67) infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
