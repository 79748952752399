.root {
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-width: 704px;
    padding-top: 10px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px
}
