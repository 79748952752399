.container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.titleContainer {
    padding-top: 10px;
    padding-bottom: var(--spacing-sm);
}

.createNoteButton {
    justify-content: flex-end;
    display: flex;
}

@media (max-width: 768px) {
    .createNoteButton button {
        width: 100%;
    }
}
