ul.Text {
    list-style: disc;
    margin-left: 1.5rem;
}

ul.Text,
ul.Text li {
    margin-bottom: 1rem;
}
ul.Text:last-child,
ul.Text li:last-child {
    margin-bottom: 0;
}

blockquote.Text {
    padding: 0.5rem 1rem;
    border-left: 4px solid var(--gray-200);
}

blockquote.Text:last-child {
    margin-bottom: 0;
}
