.root {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px
}

.address {
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    column-gap: 16px;
}
