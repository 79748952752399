.titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.icon {
    color: var(--error-500);
    font-size: 48px;
}
