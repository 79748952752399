.icon {
    display: inline-block;
    position: relative;
    width: 1.25em;
    height: 1.25em;
    font-size: inherit;
    line-height: inherit;
}

.icon::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: inherit;
    line-height: inherit;
}

.icon svg {
    display: block;
    width: 100%;
    height: 100%;
}
