.dateItem {
    font-weight: 500;
}
.dateItem:first-letter {
    text-transform: uppercase;
}
.dateItem::after {
    content: '';
    position: absolute;
    background-color: var(--gray-100);
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
}
