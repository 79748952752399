.backdrop {
    background: rgba(20, 32, 60, 0.40);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.backdropVisible {
    animation: backdropFadeIn 0.2s ease-in forwards;
}

.backdropHidden {
    animation: backdropFadeOut 0.2s ease-out forwards;
}

.mainContainer {
    display: flex;
    width: 704px;
    margin: auto;
    max-width: 100vw;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.innerContainer {
    position: relative;
    overflow: hidden;
    background-color: white;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: scale(0.9);
    will-change: opacity, transform;
}

.innerContainer.visible {
    animation: fadeIn 0.2s ease-in forwards;
}

.innerContainer.hidden {
    animation: fadeOut 0.2s ease-out forwards;
}

.canOverflow .innerContainer {
    overflow: visible;
}

.flowyFigureContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 16px;
    z-index: -1;
}

.contentContainer {
    display: flex;
    padding: var(--spacing-md);
    gap: 8px;
    max-height: 100vh;
}

.backdrop:not(.nonScrollable) .contentContainer {
    overflow-y: auto;
}

.childrenContainer {
    width: 100%;
    height: 100%;
}

.iconContainer {
    position: sticky;
    top: 0;
    width: 48px;
    pointer-events: none;
}

.icon {
    vertical-align: middle;
    line-height: 48px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
}

.closeButton {
    position: absolute;
    right: 8px;
    top: 8px;
    margin-top: 8px;
    margin-right: 8px;
    z-index: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.9);
    }
}

@keyframes backdropFadeIn {
    from {
        background: rgba(20, 32, 60, 0);
    }
    to {
        background: rgba(20, 32, 60, 0.40);
    }
}

@keyframes backdropFadeOut {
    from {
        background: rgba(20, 32, 60, 0.40);
    }
    to {
        background: rgba(20, 32, 60, 0);
    }
}

@media (max-width: 768px) {
    .mainContainer {
        width: 100vw;
    }

    .contentContainer {
        flex-direction: column;
        align-items: center;
    }

    .innerContainer {
        height: 100vh;
        border-radius: 0;
    }
}
