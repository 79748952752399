.sidePanel {
    width: 520px;
    height: 100%;
    padding: var(--spacing-md) var(--spacing-lg);
    border-left: 1px solid var(--gray-200);
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 0;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
    transform: translateX(100%);
    visibility: hidden;
    z-index: 61;
}

.sidePanel.open {
    transform: translateX(0);
    visibility: visible;
}

.sidePanelPadding {
    padding-bottom: var(--spacing-9xl);
}

.backButtonContainer {
    display: none;
    padding: 0 0 var(--spacing-md) 0;
}

.backButton {
    padding: 0;
}

.wrapper {
    max-width: 1280px;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--spacing-md);
}

.closeButton {
    display: block;
}

@media (max-width: 768px) {
    .sidePanel {
        width: 100%;
    }

    .backButtonContainer {
        display: block;
    }

    .closeButton {
        display: none;
    }
}
