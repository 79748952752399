.dateContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: var(--spacing-md);
    font-weight: 500;
    color: var(--gray-800);
}

.dateText {
    margin-bottom: var(--spacing-sm);
}

.checkboxDiv {
    gap: 6px;
}

.messageBodyWithTime {
    display: flex;
    grid-template-columns: 1fr;
    padding: var(--spacing-sm) var(--spacing-md);
    gap: var(--spacing-md);
    background-color: var(--gray-50);
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    margin-top: var(--spacing-sm);
}

.messageBodyWithTime p {
    color: var(--gray-500);
    font-family: "Plus Jakarta Sans", serif;
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 400;
}

.formSubText {
    margin-top: 6px;
}

.titleContainer {
    padding-top: var(--spacing-sm);
    padding-bottom: var(--spacing-md);
    font-weight: 700;
    line-height: 2rem;
}

.maxCandidatesText {
    font-size: var(--spacing-md);
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-800);
    white-space: pre-wrap;
}

.subjectTitle {
    font-size: var(--spacing-md);
    font-weight: 500;
    color: var(--gray-800);
}

.includeInEmailText {
    font-size: var(--spacing-md);
    font-weight: 500;
    color: var(--gray-800);
}

.buttonTitle {
    margin-bottom: var(--spacing-sm);
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-lg);
}

.maxCandidatesInput {
    margin-top: var(--spacing-sm);
    padding: var(--spacing-sm);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    transition: box-shadow 0.2s, border-color 0.2s;
    border: 1px solid var(--gray-300);
    border-radius: 0.375rem;
}

.maxCandidatesInput:focus {
    border-color: var(--primary-300);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 0 0 4px var(--primary-100);
}

.maxCandidatesInput.block {
    display: block;
}

.maxCandidatesInput.sm-text {
    font-size: 0.875rem;
}

.maxCandidatesInput:disabled {
    background-color: #F8FAFC;
    cursor: not-allowed;
}
