.bucketContainer {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.listingsHeader {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-200);
    padding: 0 var(--spacing-5xl);
}
