.root {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 40px;
    max-width: 1280px;
}

.content {
    padding-left: 24px;
    padding-right: 24px;

    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
}

.idInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: fit-content;
}

.tableHeader {
    background: var(--gray-100);
    padding-top: 8px;
    padding-bottom: 8px;
}

.tableRowText {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 24px;
}

.tableRowButtons {
    display: flex;
    align-items: center;
    gap: 8px;
}
