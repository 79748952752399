.text {
    color: var(--gray-500, #697082);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}
