.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.md {
    width: 113px;
    height: 42px;
}

.md svg {
    width: 113px;
    height: 32px;
}

.clickable {
    cursor: pointer;
}
