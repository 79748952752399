.titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.iconFrame {
    display: flex;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    background-color: var(--success-200);
    align-items: center;
    justify-content: center;
}
