.Label {
    display: flex;
    justify-content: space-between;
}

.labelText {
    font-size: var(--spacing-md);
    font-weight: 500;
    color: var(--gray-800);
    flex: 1;
}

.labelText strong {
    font-size: var(--spacing-md);
    font-weight: 700;
    color: var(--gray-800);
}

.DropdownHeader {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.BadgeCloseIcon {
    cursor: pointer;
}

.CollapseSelectedCounter > * {
    cursor: pointer;
}
