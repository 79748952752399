.SaveableInput {
    position: relative;
    display: flex;
}

.input {
    padding-left: 1.75rem;
    padding-right: 0rem;
    border-radius: 0.375rem;
    border-width: 1px;
    outline: none;
    cursor: pointer;
    border-color: transparent;
    width: 120px;
    box-shadow: 0px 1px 2px 0px transparent, 0px 0px 0px 4px transparent;
    transition: 100ms ease-in padding-left,
                100ms ease-in box-shadow,
                100ms ease-in border-color,
                100ms ease-in width;
}

.input:focus {
    padding-left: 2.625rem;
    padding-right: 0.75rem;
    border-radius: 8px;
    width: 236px;
    border-color: var(--primary-300);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary-100);
}

.euroIcon {
    position: absolute;
    font-size: 1.25rem;
    line-height: 1.75rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 100ms ease-in left;
}

.input:focus + .euroIcon {
    left: 0.875rem;
}

.editAndSaveIcon {
    position: relative;
    font-size: 1.25rem;
    line-height: 2.75rem;
    width: 2.625rem;
}
.editAndSaveIcon i {
    position: absolute;
    padding: 0 0.75rem;
    cursor: pointer;
    color: var(--gray-500);
}
.editIcon {
    pointer-events: auto;
    opacity: 1;
    transition: 100ms ease-in opacity;
}
.input:focus ~ .editAndSaveIcon .editIcon {
    pointer-events: none;
    opacity: 0;
}
.saveIcon {
    pointer-events: none;
    opacity: 0;
    transition: 100ms ease-in opacity;
}
.input:focus ~ .editAndSaveIcon .saveIcon {
    pointer-events: auto;
    opacity: 1;
}
