.Badge {
    display: inline-flex;
    padding: 3px 8px 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 22.5rem;
    border: 1px solid var(--primary-700);
    background: var(--primary-50);
    color: var(--primary-700);
    cursor: default;
}

.label {
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.Badge > div {
    display: flex;
    height: 1.25rem;
    align-items: center;
}
